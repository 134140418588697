import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'error'];

  connect() {
    this.inputTarget.addEventListener('input', this.validateInput.bind(this));
  }

  validateInput() {
    // 只允许数字输入并限制6位
    const rawValue = this.inputTarget.value.replace(/\D/g, '');
    this.inputTarget.value = rawValue.slice(0, 6);

    // 自动提交表单当输入6位时
    if (rawValue.length === 6) {
      this.element.requestSubmit();
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    fetch(this.element.action, {
      method: 'POST',
      body: new FormData(this.element),
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response.text();
      })
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => {
        const errorTarget = this.element.querySelector('#access-code-error');
        if (errorTarget) {
          errorTarget.textContent = '验证失败，请重试';
          errorTarget.classList.remove('hidden');
        }
      });
  }
}
